import { createStore } from 'vuex';
import {
  getStandingList,
  getUStationList,
  getAedDetailByArea,
  getAxedDetailByArea,
} from '@/api';
import axios from 'axios';
const jsonp = require('jsonp');
// 计算距离
const walkingNaviTime = function (origin, destination, callback) {
  jsonp(
    `https://api.map.baidu.com/directionlite/v1/walking?origin=${origin}&destination=${destination}&ak=` +
      window.sessionStorage.getItem('mapAk2'),
    null,
    (err, data) => {
      if (err) {
        console.log(err);
      } else {
        if (data.status === 3003) {
          callback('00:00:00');
        } else {
          callback(getTime(data.result.routes[0].duration));
        }
      }
    }
  );
};
function getTime(time) {
  // 转换为时分秒
  let h = parseInt((time / 60 / 60) % 24);
  h = h < 10 ? '0' + h : h;
  let m = parseInt((time / 60) % 60);
  m = m < 10 ? '0' + m : m;
  let s = parseInt(time % 60);
  s = s < 10 ? '0' + s : s;
  // 作为返回值返回
  return `${h}:${m}:${s}`;
}

export default createStore({
  state: {
    /* *******************侧边栏显示隐藏************* */
    headers: {
      infoActive: false,
      equipActive: false,
      progressActive: false,
      resourceActive: false,
    },
    asiderRight: {
      videoActive: false, // 视频组件显示隐藏
      stageActive: false, // 信息平台、筛选显示隐藏
    },
    mapVideoActive: false, // 地图放大后视频组件显示隐藏
    /* *******************设备数据******************* */
    aed: [], // 所有aed数据
    aedArea: [], // 所有aed分区的数据
    axed: [], // 所有axed数据
    axedArea: [], // axed分区的数据
    standing: [], // 所有应急响应人站点数据
    standingArea: [], // 应急响应人分区数据
    UStation: [], // 所有U站数据
    UStationArea: [], // U站分区数据
    unOnlineUsers: [], // 未上过线用户数组
    unOnlineArea: [], // 未上过线用户分区数据
    equipDetail: {}, // 左侧点击设备地图展示相应信息
    equipPosition: {}, // 点击侧边栏设备 地图移动至该区域
    unOnlineDetail: {}, // 点击地图未上线信息
    carAed: [], // 车载设备数据
    carAedDetail: {}, // 点击的车载设备数据
    /* *************************地图数据mapv显示隐藏 ************************ */
    mapLoading: true, // 地图加载中
    userLayerIsShow: true, // 应急第一响应人数据(在线、未上线)
    aedLayerIsShow: true, // aed数据
    axedLayerIsShow: true, // 安心E站数据
    UStationLayerIsShow: true, // U站数据
    standingSiteLayerIsShow: true, // 应急响应人站点数据
    carAedLayerIsShow: true, // 车载设备数据
    /* *******************呼救********************* */
    infoLoading: false,
    infoList: [], // 急救事件列表
    infoDetail: {}, // 事件详情
    infoPosition: {}, // 事件经纬度
    volAboutEvnetNavi: [], // 事件相关接受派遣的志愿者导航数组
    notifyList: [], // 消息队列
    inRangeUser: [], // 地图圆圈范围内志愿者
    sendEquipInfo: '', // 发送的应急物资
    /* *****************志愿者数据*************** */
    screenValue: 0, // 志愿者在线时间筛选
    getWechat: true, // 是否获取小程序用户(应急力量筛选)
    getWatch: true, // 是否获取手表用户(应急力量筛选)
    getUnOnline: true, // 是否显示未上过线用户(应急力量筛选)
    expert: [], // 专家数组
    volunteer: [], // 普通志愿者数组
    rider: [], // 骑手数组
    didi: [], // 滴滴数组
    activeUsers: [], // 所有的在线用户(根据identify属性区分手环、小程序在线)
    userDetail: {}, // 选中的用户信息
    userPosition: {}, // 志愿者经纬度
    /* *********************视频************** */
    inVideo: false, // 判断当前是否有在视频通话中
    videoingvolun: null, //正在视频通话的志愿者
    volumteerViedo: true, //志愿者拒绝加入视频
    videoController: false, //控制调度员开启视频通话
    inviteUserByInfoIsShow: false, // 邀请用户视频列表(事件信息:呼救者、志愿者)
    inviteUserByVideoIsShow: false, // 邀请用户视频列表(视频组件:志愿者、专家)
    /* *****************外呼****************** */
    tccc: null,
    websocketIsConnect: false, // websocket
  },
  getters: {},
  mutations: {
    // 控制websocket连接
    websocketHandle(state, payload) {
      state.websocketIsConnect = payload;
    },
    /* *******************控制侧边栏显示隐藏******************* */
    // 事件信息显示隐藏
    showOrHideInfo(state) {
      state.headers.infoActive = !state.headers.infoActive;
    },
    // 事件进度显示隐藏
    showOrHidePro(state) {
      state.headers.progressActive = !state.headers.progressActive;
    },
    // 应急力量显示隐藏
    showOrHideRes(state) {
      state.headers.resourceActive = !state.headers.resourceActive;
    },
    // 应急设备显示隐藏
    showOrHideEqui(state) {
      state.headers.equipActive = !state.headers.equipActive;
    },
    showOrHideStageActive(state, payload) {
      state.asiderRight.stageActive = payload;
    },
    /* *********************视频操作********************* */
    // 显示隐藏视频组件
    showOrHideVideo(state, payload) {
      state.asiderRight.videoActive = payload;
      if (state.mapVideoActive) {
        state.mapVideoActive = false;
      }
    },
    // 显示隐藏地图视频组件
    showOrHideMapVideo(state) {
      state.mapVideoActive = !state.mapVideoActive;
      state.asiderRight.videoActive = !state.asiderRight.videoActive;
      if (state.mapVideoActive) {
        state.asiderRight.stageActive = true;
      }
    },
    hideAllVideo(state) {
      state.inVideo = false;
      state.asiderRight.videoActive = false;
      state.mapVideoActive = false;
      state.asiderRight.stageActive = true;
    },
    changeVideoStatus(state, payload) {
      state.inVideo = payload;
    },
    /* **********************呼救操作************************ */
    // 发送设备
    sendEquip(state, payload) {
      state.sendEquipInfo = payload;
      console.log(state.sendEquipInfo);
    },
    /* 
    志愿者接收派遣将传来的志愿者id与在线用户列表进行比对
    并存入事件相关接受派遣的志愿者导航数组
    比对不出来则设置定时器直到比对出来
  */
    volNavigationEvent(state, payload) {
      state.activeUsers.forEach((el) => {
        if (el.id == payload) {
          state.volAboutEvnetNavi.push(el);
        }
      });
    },
    // 接受派遣改变事件状态(任务中)
    changeEventState(state, payload) {
      state.infoList.forEach((el) => {
        if (el.id == payload) {
          el.status = 1;
        }
      });
    },
    // 获取呼救信息
    getInfo(state, payload) {
      state.infoList = payload.data;
      if (state.infoList.length != 0) {
        state.headers.progressActive = true;
      }
    },
    // 接收到新事件
    getNewInfo(state, payload) {
      state.infoList.unshift(payload);
    },
    // 将已完成的消息从列表中去除
    spliceNotify(state, payload) {
      state.notifyList.splice(payload, 1);
    },
    // 事件完成，在事件进度删除相关信息
    eventComplete(state, payload) {
      state.infoList.forEach((el, index) => {
        el.id === payload ? state.infoList.splice(index, 1) : '';
      });
    },
    // 点击事件进度选择事件
    showInfo(state, payload) {
      state.infoLoading = true;
      setTimeout(() => {
        state.infoDetail = payload;
        state.infoLoading = false;
      }, 0);
      state.headers.infoActive = true;
      state.asiderRight.stageActive = true;
      // 计算志愿者与事件的距离
      if (Object.keys(payload).length != 0) {
        let map = new BMap.Map('');
        // 事件经纬度
        let pointA = new BMap.Point(
          parseFloat(payload.lng),
          parseFloat(payload.lat)
        );
        state.activeUsers.forEach((el) => {
          // 志愿者经纬度
          let pointB = new BMap.Point(parseFloat(el.lng), parseFloat(el.lat));
          // 计算距离
          el.distance = (map.getDistance(pointA, pointB) / 1000).toFixed(2);
        });
        state.unOnlineUsers.forEach((el) => {
          // 未上线用户
          let pointB = new BMap.Point(parseFloat(el.lng), parseFloat(el.lat));
          // 计算距离
          el.distance = (map.getDistance(pointA, pointB) / 1000).toFixed(2);
        });
        state.activeUsers.sort((a, b) => a.distance - b.distance);
        state.activeUsers.sort((a, b) => a.identify - b.identify);
      }
    },
    // 移动至急救标志物
    moveToInfo(state, payload) {
      state.infoPosition = payload;
      // setTimeout(() => {
      //   state.infoPosition = {};
      // }, 0);
    },
    // 点击的志愿者
    showUser(state, payload) {
      state.userDetail = payload;
      state.headers.resourceActive = true;
    },
    // 地图移动至志愿者
    moveToUser(state, payload) {
      state.userPosition = payload;
      setTimeout(() => {
        state.userPosition = {};
      }, 0);
    },
    // 点击的车载设备
    showCadAed(state, payload) {
      state.carAedDetail = payload;
    },
    // 点击的设备
    showEquip(state, payload) {
      state.equipDetail = payload;
    },
    // 移动至设备
    moveToEquip(state, payload) {
      state.equipPosition = payload;
      setTimeout(() => {
        state.equipPosition = {};
      }, 0);
    },
    // push新消息进消息队列
    pushNotify(state, payload) {
      state.notifyList.push(payload);
    },
    showUnOnline(state, payload) {
      state.unOnlineDetail = payload;
    },
    getInRangeUser(state, payload) {
      state.inRangeUser = payload;
    },
    /* ********************导航相关操作*********** */
    // 点击事件进度获取事件相关接受派遣志愿者并存入导航数组
    showNavigation(state, payload) {
      if (payload.length === 0) {
        // 关闭事件信息取消导航
        state.volAboutEvnetNavi = payload;
      } else {
        // 步行导航只绘制当前在线的用户
        let arr = [];
        state.activeUsers.forEach((el) => {
          payload.forEach((item) => {
            if (el.id === item.id) {
              arr.push(el);
            }
          });
        });
        state.volAboutEvnetNavi = arr;
      }
    },
    // 志愿者轮询时判断是否有在事件中 有的话进行导航
    isNavigation(state, payload) {
      let arr = [];
      state.activeUsers.forEach((el) => {
        payload.forEach((item) => {
          if (el.id === item.id) {
            arr.push(el);
          }
        });
      });
      state.volAboutEvnetNavi = arr;
    },
    // 计算预计抵达时间
    getDuration(state, payload) {
      state.activeUsers.forEach((el) => {
        payload.forEach((item) => {
          if (el.id === item.id) {
            // Vue.set(el, 'duration', item.duration);
            el.duration = item.duration;
          }
        });
      });
    },
    /* **********************地图数据操作********************* */
    // 控制地图mapv图层显示隐藏
    layerHandle(state, payload) {
      state[payload] = !state[payload];
    },
    // 将接口获取的车载设备数据存放至state
    getCar(state, payload) {
      state.carAed = payload.data;
      state.carAed.forEach((el) => {
        if (el.id == state.carAedDetail.id) {
          state.carAedDetail = el;
        }
      });
    },
    // 将接口获取的aed数据存放至state
    getAed(state, payload) {
      state.aedArea = payload.data;
      payload.data.forEach((area) => {
        area.info.forEach((item) => {
          state.aed.push(item);
          state.mapLoading = false;
        });
      });
    },
    // 将接口获取的安心驿站数据存放至state
    getAXED(state, payload) {
      state.axedArea = payload.data;
      payload.data.forEach((area) => {
        area.info.forEach((item) => {
          state.axed.push(item);
        });
      });
    },
    // 将接口获取的应急响应人站点数据存放至state
    getStanding(state, payload) {
      // state.standing = payload.data;
      state.standingArea = payload.data;
      payload.data.forEach((area) => {
        area.info.forEach((item) => {
          state.standing.push(item);
        });
      });
    },
    // 将接口获取的U站数据存放至state
    getUStation(state, payload) {
      // state.UStation = payload.data;
      state.UStationArea = payload.data;
      payload.data.forEach((area) => {
        area.info.forEach((item) => {
          state.UStation.push(item);
        });
      });
    },
    // 获取未上线用户
    getUnOnlineUsers(state, payload) {
      payload.forEach((area) => {
        area.info.forEach((el) => {
          el.identify = 3;
          el.isOnline = false;
          el.isUnOnline = true;
          state.unOnlineUsers.push(el);
        });
      });
      state.unOnlineArea = payload;
    },
    // 分区筛选
    areaDisplay(state, payload) {
      let newAed = [];
      let newAxed = [];
      let newStanding = [];
      let newUStation = [];
      let newUnOnlineUser = [];
      payload.forEach((el) => {
        state.aedArea.forEach((item, index) => {
          if (index == el) {
            item.info.forEach((element) => {
              newAed.push(element);
            });
          }
        });
        state.axedArea.forEach((item, index) => {
          if (index == el) {
            item.info.forEach((element) => {
              newAxed.push(element);
            });
          }
        });
        state.standingArea.forEach((item, index) => {
          if (index == el) {
            item.info.forEach((element) => {
              newStanding.push(element);
            });
          }
        });
        state.UStationArea.forEach((item, index) => {
          if (index == el) {
            item.info.forEach((element) => {
              newUStation.push(element);
            });
          }
        });
        state.unOnlineArea.forEach((item, index) => {
          if (index == el) {
            item.info.forEach((element) => {
              newUnOnlineUser.push(element);
            });
          }
        });
      });
      state.aed = newAed;
      state.axed = newAxed;
      state.standing = newStanding;
      state.UStation = newUStation;
      state.unOnlineUsers = newUnOnlineUser;
    },
    // 勾选小程序
    chooseWechat(state, payload) {
      state.getWechat = payload;
    },
    // 勾选手环
    chooseWatch(state, payload) {
      state.getWatch = payload;
    },
    // 勾选未上线
    chooseUnOnline(state, payload) {
      state.getUnOnline = payload;
    },
    /* *******************获取志愿者数据**************** */
    // 选择志愿者在线时间
    chooseScreen(state, payload) {
      state.screenValue = payload;
    },
    // 获取所有志愿者数据
    getActiveUsers(state, payload) {
      if (Object.keys(state.infoDetail).length > 0) {
        let map = new BMap.Map('');
        // 事件经纬度
        let pointA = new BMap.Point(
          parseFloat(state.infoDetail.lng),
          parseFloat(state.infoDetail.lat)
        );
        payload.forEach((el) => {
          // 志愿者经纬度
          let pointB = new BMap.Point(parseFloat(el.lng), parseFloat(el.lat));
          // 计算距离
          el.distance = (map.getDistance(pointA, pointB) / 1000).toFixed(2);
        });
        // 排序
        payload.sort((a, b) => a.distance - b.distance);
        payload.sort((a, b) => a.identify - b.identify);
        state.activeUsers = payload;
      } else {
        payload.sort((a, b) => a.distance - b.distance);
        payload.sort((a, b) => a.identify - b.identify);
        state.activeUsers = payload;
        let isOn = false;
        payload.forEach((el) => {
          if (el.id == state.userDetail.id) {
            isOn = true;
          }
        });
        if (!isOn) {
          state.userDetail = {};
        }
      }
    },
    // 获取专家信息
    getExpert(state, payload) {
      state.expert = payload;
    },
    // 获取普通志愿者信息
    getVolunteer(state, payload) {
      state.volunteer = payload;
    },
    // 获取骑手志愿者信息
    getRider(state, payload) {
      state.rider = payload;
    },
    // 获取滴滴志愿者信息
    getDidi(state, payload) {
      state.didi = payload;
    },
    /* ********************外呼************* */
    getTccc(state, payload) {
      state.tccc = payload;
    },
    // 进行通话
    tcccCall(state, payload) {
      let options = {
        phoneNumber: payload,
      };
      let str = state.tccc.Agent.getStatus();

      if (str != 'free') {
        alert('坐席不在线,请刷新');
      } else {
        state.tccc.Call.startOutboundCall(options);
      }
    },
    /* **************************视频***************************** */
    //正在通话的志愿者
    setVideoingvolun(state, payload) {
      state.videoingvolun = payload;
    },
    //志愿者拒绝加入视频
    setVolumteerViedo(state, payload) {
      state.volumteerViedo = payload;
    },
    //控制调度员开启视频
    setVideoController(state, payload) {
      state.videoController = payload;
    },
    showOrHideInviteUserByInfo(state, payload) {
      state.inviteUserByInfoIsShow = payload;
    },
    showOrHideInviteUserByVideoIsShow(state, payload) {
      state.inviteUserByVideoIsShow = payload;
    },
  },
  actions: {
    // 获取设备数据
    getEquip(ctx) {
      getStandingList().then((standRes) => {
        ctx.commit('getStanding', standRes);
        getUStationList().then((uRes) => {
          ctx.commit('getUStation', uRes);
          getAxedDetailByArea().then((axedRes) => {
            ctx.commit('getAXED', axedRes);
            getAedDetailByArea().then((aedRes) => {
              ctx.commit('getAed', aedRes);
            });
          });
        });
      });
    },
    // 获取车载设备数据
    getCarAed(ctx) {
      axios({
        url: 'https://gateway.first-responder.cn:7000/scheduling-bat/aed/vehicle-information/list',
        method: 'get',
        headers: {
          Authorization: window.sessionStorage.getItem('token'),
        },
      }).then((res) => {
        ctx.commit('getCar', res.data);
      });
    },
    // 导航相关操作 (计算预计抵达时间)
    _getDuration(option) {
      if (option.state.volAboutEvnetNavi.length != 0) {
        let naviUsers = [];
        // 事件经纬度
        let origin = `
      ${option.state.infoDetail.lat.toFixed(
        6
      )},${option.state.infoDetail.lng.toFixed(6)}`;
        option.state.activeUsers.forEach((el) => {
          option.state.volAboutEvnetNavi.forEach((item) => {
            if (el.id === item.id) {
              // 志愿者经纬度
              let destination = `${el.lat.toFixed(6)},${el.lng.toFixed(6)}`;
              // 计算预计时间
              walkingNaviTime(origin, destination, (res) => {
                naviUsers.push({
                  id: el.id,
                  duration: res,
                });
                option.commit('getDuration', naviUsers);
              });
            }
          });
        });
      }
    },
  },
  modules: {},
});
